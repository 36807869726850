
import React from "react"
import "./button.css"
import {
    FaArrowRight,
  } from 'react-icons/fa'

function Button(props){
    const bgColor = props.color || 1;
    const sizeSmall = props.size === "small";
    return (
        <button className={"button" + (sizeSmall ? ` button--small` : "")} onClick={props.onClick}>
            <div className={"button-bg" + (bgColor ? ` bg-color-accent-${bgColor}` : "")}></div>
            <span className="button-text vam">{props.children || "Read more"}</span>
            <FaArrowRight className="icon"></FaArrowRight>
        </button>
    );
}

export default Button